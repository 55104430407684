import store from "@/store";
import { i18n } from '@/plugins/i18n'

export const copyToClipboard = (text) => {
	if (window.clipboardData && window.clipboardData.setData) {
		return window.clipboardData.setData("Text", text);
	}
	else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
		var textarea = document.createElement("textarea");
		textarea.textContent = text;
		textarea.style.position = "fixed";
		document.body.appendChild(textarea);
		textarea.select();
		try {
			store.commit('notification/successMessage', {title: i18n.t("account.messages.success.link_copied")})
			return document.execCommand("copy");
		}
		catch (ex) {
			store.commit('notification/errorMessage', {title: i18n.t("account.messages.errors.sorry_failed_to_copy")})
			return false;
		}
		finally {
			document.body.removeChild(textarea);
		}
	}
}
